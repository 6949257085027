/*@import "@fortawesome/fontawesome-pro/css/all.min.css";*/
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap');
/*@import "bootstrap-icons/font/bootstrap-icons.css"; !* You can add global styles to this file, and also import other style files *!*/

.title {
  font-size: 2rem;
}

html body {
  font-family: 'Titillium Web', sans-serif !important;
}


.cursor-pointer {
  cursor: pointer;
}

.text-c96311 {
  color: #c96311;
}

.bg-e0ecf2 {
  background-color: #e0ecf2 !important;
}

input.switches-color {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236fb2ee'/%3e%3c/svg%3e") !important;
  border-color: #6fb2ee !important;
}

.text-primary {
  color: #174f84 !important;
}

.bg-174f84 {
  background-color: #174f84 !important;
}

.text-256eb3,
.color-256eb3 {
  color: #256eb3 !important;
}

.text-3c7eba {
  color: #3c7eba !important;
}

.round-button {
  width: 38px;
  height: 38px;
}

.bg-f3fbff {
  background-color: #f3fbff !important;
}

.btn-primary,
.bg-256eb3 {
  background-color: #256eb3 !important;
}

.btn-primary,
.btn-primary:hover {
  color: #fff;
}

.bg-f8f8f9 {
  background-color: #f8f8f9 !important;
}

.bg-success {
  background-color: #1c7c47 !important;
}

.bg-danger {
  background-color: #b0132b !important;
}

.small {
  font-size: .875em;
}

.bs-popover-start > .popover-arrow::after, .bs-popover-bottom > .popover-arrow::after, .bs-popover-start > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::before {
  border-width: 0;
}


input,
select {
  border-color: #333333 !important;
  color: #8c8c8c !important;
}

ngb-tooltip-window .btn:hover {
  color: #3c7eba !important;
}

.date-picker button {
  color: #8c8c8c;
  border-left: none !important;
}

.date-picker input {
  border-right: none;
}

.bg-d1ebe2 {
  background-color: #d1ebe2 !important;
}

.btn-256eb3 {
  background-color: #256eb3 !important;
  color: #fff;
}

.bg-toast-success {
  background-color: #e8f4ef !important;
}

.bg-toast-error {
  background-color: #f7eaed !important;
}

.bg-toast-warning {
  background-color: #faf0e7 !important;
}

.text-warning {
  color: #cc6511 !important;
}

.border-warning {
  border-color: #cc6511 !important;
}

.date-picker button:active,
.date-picker button:hover,
.date-picker button:focus-visible {
  color: #333333 !important;
}

.input-button:disabled {
  opacity: 1 !important;
  background-color: #e9ecef !important;
}

.input-button,
.date-picker button,
.date-picker input {
  border-color: #333333 !important;
  background-color: transparent !important;
}

.lh-inherit {
  line-height: inherit !important;
}

.modal-content {
  border-radius: 15px !important;
  border-width: 1px;
  border-color: #8c8c8c;
}

.modal-header {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip-inner {
  background-color: #fff !important;
  border-color: #8c8c8c;
  border-width: 1px;
  border-style: solid;
}

.tooltip-arrow {
  display: none !important;
}
